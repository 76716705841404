<template>
  <div id="sign-in">
    <!-- begin:: Page -->

    <div class="kt-grid kt-grid--ver kt-grid--root" v-show="!hasDecided">
      <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid kt-grid--hor kt-login-v2"
        id="kt_login_v2"
      >
        <!--begin::Item-->
        <div class="kt-grid__item kt-grid--hor">
          <!--begin::Heade-->
          <div class="kt-login-v2__head">
            <div class="kt-login-v2__logo">
              <a href="/">
                <img
                  alt="Logo"
                  style="width: 33px; height: 33px"
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F09ecaa_2c390b832a89448697f16121a49e61da_mv2.png?alt=media&token=c25ed621-3189-496a-9910-f9c093c32e14"
                />
              </a>
            </div>
            <div class="kt-login-v2__signup">
              <span>Already have an account?</span>
              <a href="/signin" class="kt-link kt-font-brand">Sign In</a>
            </div>
          </div>
          <!--begin::Head-->
        </div>
        <!--end::Item-->

        <div>
          <div
            class="card text-center shadow-sm"
            style="margin: auto; max-width: fit-content"
          >
            <!-- <div class="card-body">
              <h1
                class="card-title"
                style="margin-top: 10px; margin-bottom: 10px;"
              >
                Select the plan that's right for you
              </h1>
            </div> -->
          </div>
          <div class="customArea">
            <!-- <div
              class="card shadow-sm"
              style="margin-left: 10px; min-width: 400px"
            >
              <div class="card-header">
                <h3 class="my-0 font-weight-normal">Learning Centers Plan</h3>
              </div>
              <div class="card-body">
                <h1 class="card-title pricing-card-title">
                  $100 once<br/>
                  <small class="text-muted">then</small><br/>
                  $120 <small class="text-muted">/ year per tutor</small>
                </h1>
                <ul class="list-unstyled mt-3 mb-4">
                  <li>- *Previously purchased customers only pay $120/year</li>
                  <li>- Create a learning center admin account</li>
                  <li>- Unlimited tutor acconts</li>
                  <li>- $120/year for additional tutors</li>
                  <li>- Priority support</li>
                </ul>
                <button
                  type="button"
                  @click="
                    hasDecided = true;
                    selectedAccountType = 'learningCenter';
                  "
                  class="btn btn-lg btn-block btn-primary"
                  style="padding: 15px 30px"
                >
                  Get started
                </button>
              </div>
            </div> -->

            <div
              class="card shadow-sm"
              style="margin-left: 10px; min-width: 400px"
            >
              <div class="card-header">
                <h3 class="my-0 font-weight-normal">Professional Plan</h3>
              </div>
              <div class="card-body">
                <h1 class="card-title pricing-card-title">
                  $39 once
                  <!-- <br/>
                  <small class="text-muted">then</small><br/>
                  $37 <small class="text-muted">/ month</small> -->
                </h1>
                <ul class="list-unstyled mt-3 mb-4">
                  <!-- <li>- *Previously purchased customers's $375 fee is wavied</li> -->
                  <li>- $124/year per tutor account</li>
                  <li>- $14/month per student</li>
                  <li>- Managed tutors</li>
                  <li>- Assign students to tutors</li>
                  <li></li>
                  <li>- Create a professional admin account</li>
                  <li>- Unlimited data entry</li>
                  <li>- Progress tracking</li>
                  <li>- Unlimited support</li>
                </ul>
                <button
                  type="button"
                  @click="
                    hasDecided = true;
                    selectedAccountType = 'tutorAdmin';
                  "
                  class="btn btn-lg btn-block btn-primary"
                  style="padding: 15px 30px"
                >
                  Get started
                </button>
              </div>
            </div>

            <div
              class="card shadow-sm"
              style="margin-left: 10px; min-width: 400px"
            >
              <div class="card-header">
                <h3 class="my-0 font-weight-normal">
                  Independent Study / Homeschool Plan
                </h3>
              </div>
              <div class="card-body">
                <h1 class="card-title pricing-card-title">
                  <!-- $0 one time fee<br/>
                  <small class="text-muted">then</small><br/> -->
                  $160 <small class="text-muted">/ 6 months</small>
                </h1>
                <ul class="list-unstyled mt-3 mb-4">
                  <li>- 180 day access</li>
                  <li>- Up to 2 students</li>
                  <li>- First student is $160.00</li>
                  <li>- Second student for $40.00</li>
                  <!-- <li>- Online Training and Resources</li> -->
                  <li>- Unlimited Support</li>
                </ul>
                <button
                  type="button"
                  @click="
                    hasDecided = true;
                    selectedAccountType = 'homeschooler';
                  "
                  class="btn btn-lg btn-block btn-primary"
                  style="padding: 15px 30px"
                >
                  Get started
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--end::Wrapper-->
      </div>
    </div>

    <div class="kt-grid kt-grid--ver kt-grid--root" v-show="hasDecided">
      <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid kt-grid--hor kt-login-v2"
        id="kt_login_v2"
      >
        <!--begin::Item-->
        <div class="kt-grid__item kt-grid--hor">
          <!--begin::Heade-->
          <div class="kt-login-v2__head">
            <div class="kt-login-v2__logo">
              <a href="/">
                <img
                  alt="Logo"
                  style="width: 33px; height: 33px"
                  src="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2F09ecaa_2c390b832a89448697f16121a49e61da_mv2.png?alt=media&token=c25ed621-3189-496a-9910-f9c093c32e14"
                />
              </a>
            </div>
            <div class="kt-login-v2__signup">
              <span>Already have an account?</span>
              <a href="/signin" class="kt-link kt-font-brand">Sign In</a>
            </div>
          </div>
          <!--begin::Head-->
        </div>
        <!--end::Item-->

        <!--begin::Item-->
        <div class="kt-grid__item kt-grid kt-grid--ver kt-grid__item--fluid">
          <!--begin::Body-->
          <div class="kt-login-v2__body">
            <!--begin::Wrapper-->
            <div class="kt-login-v2__wrapper"></div>
            <!--end::Wrapper-->

            <!--begin::Wrapper-->
            <div class="kt-login-v2__wrapper">
              <div class="kt-login-v2__container">
                <div class="kt-login-v2__title">
                  <!-- <h3>Create an administrator account</h3> -->
                  <h4 style="margin-top: 20px">
                    Create a
                    <b>
                      <span v-if="selectedAccountType == 'tutorAdmin'">
                        tutor
                      </span>
                      <span v-else-if="selectedAccountType == 'homeschooler'"
                        >homeschooler</span
                      >
                      <span v-else-if="selectedAccountType == 'learningCenter'"
                        >learning center</span
                      >
                    </b>
                    admin account
                  </h4>
                </div>

                <!--begin::Form-->
                <form class="kt-login-v2__form kt-form" autocomplete="off">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="E-mail"
                      v-model="email"
                      autocomplete="on"
                      v-on:keyup.enter="auth()"
                    />
                  </div>
                  <!-- <div class="form-group">
                    <input
                      class="form-control"
                      type="password"
                      placeholder="Password"
                      v-on:keyup.enter="auth()"
                      v-model="password"
                      autocomplete="off"
                    />
                  </div> -->
                  <!--begin::Action-->
                  <div class="kt-login-v2__actions">
                    <!-- <a
                      href="javascript:;"
                      @click="passwordResetEmail()"
                      class="kt-link kt-link--brand"
                      >Forgot Password?</a
                    > -->
                    <a
                      href="javascript:;"
                      @click="sendMagicLink()"
                      v-show="!emailSent"
                      class="btn btn-brand btn-elevate btn-pill"
                      v-bind:class="{
                        disabled: !email || submittingForm,
                      }"
                      >Send confirmation email</a
                    >
                    <span v-show="emailSent" class="kt-link kt-link--brand">
                      Confirmation email sent! Check your email to continue.
                    </span>
                  </div>
                  <!--end::Action-->
                </form>
                <!--end::Form-->

                <!--begin::Separator-->
                <!-- <div class="kt-separator kt-separator--space-lg kt-separator--border-solid"></div> -->
                <!--end::Separator-->
              </div>
            </div>
            <!--end::Wrapper-->
          </div>
          <!--begin::Body-->
        </div>
        <!--end::Item-->

        <!--begin::Item-->
        <div class="kt-grid__item">
          <div class="kt-login-v2__footer">
            <div class="kt-login-v2__link">
              <a
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Text%2Fedited_pp.pdf?alt=media&token=a974cca2-ea51-48b6-9a29-f5871e14c5c3"
                class="kt-link kt-font-brand"
                >Privacy</a
              >
              <a
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Text%2Fedited_tos.pdf?alt=media&token=a3696e71-7b41-4d37-a8b0-bdb4694b0712"
                class="kt-link kt-font-brand"
                >Terms</a
              >
              <a
                href="javascript:;"
                class="kt-link kt-font-brand"
                data-toggle="modal"
                data-target="#supportModal"
                >Contact</a
              >
            </div>

            <div class="kt-login-v2__info">
              <a href="javascript:;" class="kt-link"
                >&copy; {{ year }} Jamo Learning Inc</a
              >
            </div>
          </div>
        </div>
        <!--end::Item-->
      </div>
    </div>

    <!-- Start Support Modal -->
    <div
      class="modal fade"
      id="supportModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="supportModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="supportModalCenterTitle">
              <i class="kt-menu__link-icon flaticon-support"></i>
              Contact Support
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <br />
            <h1 class="text-center">+1 719 476-0189</h1>
            <br />
            <br />
            <h4 class="text-center text-muted">
              -- Or send us an email below --
            </h4>
            <br />
            <br />

            <form
              id="fs-frm"
              name="simple-contact-form"
              class="kt-form"
              accept-charset="utf-8"
            >
              <fieldset id="fs-frm-inputs" class="form-group">
                <label for="full-name">Full Name</label>
                <input
                  class="form-control"
                  v-model="fullName"
                  type="text"
                  name="name"
                  id="full-name"
                  placeholder="First and Last"
                  required
                />
                <br />
                <label for="email-address">Email Address</label>
                <input
                  class="form-control"
                  v-model="email"
                  type="email"
                  name="_replyto"
                  id="email-address"
                  placeholder="email@email.com"
                  required
                />
                <br />
                <label for="message">Message</label>
                <textarea
                  class="form-control"
                  v-model="message"
                  rows="5"
                  name="message"
                  id="message"
                  placeholder="Your question here."
                  required
                ></textarea>
              </fieldset>
              <a
                href="javascript:;"
                @click="submitContactForm()"
                class="btn btn-primary"
                >Submit</a
              >
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- End Support Modal -->

    <!-- end:: Page -->
  </div>
</template>

<script>
// import firebase from "./firebaseInit"
// import db from "./firebaseDB"
import $ from "jquery"; //needed?

import axios from "axios";

export default {
  name: "signin",
  data() {
    return {
      email: "",
      password: "",
      year: new Date().getFullYear(),
      submittingForm: false,
      fullName: "",
      emailContact: "",
      message: "",
      hasDecided: false,
      selectedAccountType: "",
      emailSent: false,
    };
  },
  mounted() {
    const that = this;

    // Get selectedAccountType from URL query
    const urlParams = new URLSearchParams(window.location.search);
    const accountType = urlParams.get("accountType");

    if (accountType) {
      that.hasDecided = true;
      that.selectedAccountType = accountType;
    }
  },
  methods: {
    // Send firebase magic link email
    async sendMagicLink() {
      const that = this;

      that.submittingForm = true;

      const actionCodeSettings = {
        url:
          window.location.origin +
          "/authenticate?email=" +
          that.email +
          "&accountType=" +
          that.selectedAccountType,
        handleCodeInApp: true,
      };

      await firebase
        .auth()
        .sendSignInLinkToEmail(this.email, actionCodeSettings)
        .then(function () {
          that.emailSent = true;

          // The link was successfully sent. Inform the user.
          that.$store.dispatch(
            "setInfoSuccess",
            " Confirmation email sent! Check your email to continue."
          );
          that.submittingForm = false;
        })
        .catch(function (error) {
          // Some error occurred, you can inspect the code: error.code
          that.$store.dispatch("setInfoDanger", error.message);
          that.submittingForm = false;
        });
    },
    async auth() {
      const that = this;

      that.submittingForm = true;

      const { user } = await firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          that.$store.dispatch("setInfoDanger", errorMessage);
          $("html, body").animate({ scrollTop: 0 }, "fast");
          console.error(errorCode);
          that.submittingForm = false;
        });

      // Get user's role in custom claims
      const tokenResult = await user.getIdTokenResult();
      const isAdmin = !!tokenResult.claims.admin;

      that.$store.dispatch("setAuthStatus", true);
      that.$store.dispatch("setAdminStatus", isAdmin);
      localStorage.setItem("userID", user.uid);

      // Wait 1000ms before redirecting
      setTimeout(() => {
        that.$router.push("dashboard");
        that.submittingForm = false;
      }, 1000);
    },
    passwordResetEmail() {
      const that = this;

      if (that.email !== "") {
        firebase
          .auth()
          .sendPasswordResetEmail(this.email)
          .then(function () {
            // Email sent.
            that.$store.dispatch("setInfoSuccess", "Email Sent");
          })
          .catch(function (error) {
            // An error happened.
            that.$store.dispatch("setInfoDanger", error);
            that.$store.dispatch("setInfoDanger", "");
            $("html, body").animate({ scrollTop: 0 }, "fast");
          });
      } else {
        that.$store.dispatch(
          "setInfoDanger",
          "Please enter your email address first."
        );
        $("html, body").animate({ scrollTop: 0 }, "fast");
      }
    },
    async submitContactForm() {
      await axios
        .post("/api/messageUs", {
          fullName: this.fullName,
          replyTo: this.emailContact,
          message: this.message,
        })
        .then(() => {
          alert("Message submitted!");
          $("#supportModal").modal("hide");
        });
    },
  },
};
</script>

<style scoped>
@import "./assets/app/custom/user/login-v2.demo2.css";

#sign-in {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/foundation-in-sounds-dev.appspot.com/o/Images%2FScreenshot%202023-04-05%20134239.png?alt=media&token=00046bb7-3567-45e8-b64c-fe508af89ff8");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  /* filter: blur(8px); */
  /* -webkit-filter: blur(8px); */
}

.kt-login-v2__signup,
.kt-login-v2__logo {
  padding: 20px !important;
  border-radius: 0.6rem !important;
  background-color: aliceblue !important;
}

.kt-login-v2__container {
  background-color: aliceblue;
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.5) !important;
  -webkit-box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.5) !important;
  -moz-box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.5) !important;
}

input {
  border-radius: 10px !important;
  padding-left: 5px !important;
}

.kt-login-v2__link,
.kt-login-v2__info {
  padding: 20px !important;
  border-radius: 0.6rem !important;
  background-color: aliceblue !important;
}

.kt-login-v2__footer {
  margin-top: 288px !important;
}

.customArea {
  display: flex;
  justify-content: center; /* This centers the cards horizontally when stacked side by side */
}
</style>
